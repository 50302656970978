import React, {FC, useContext, useEffect} from 'react';
import LoginForm from "./components/LoginForm";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import "./css/App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import { IntlProvider } from 'react-intl';
import { LOCALES } from './i18n/locales'
import { messages } from './i18n/messages'
import Loader from "./components/UI/Loader/Loader";

const App: FC = () => {
    let locale = LOCALES.RUSSIAN;
    const {store} = useContext(Context)
    useEffect( () => {
        if(localStorage.getItem('token')){
            store.checkAuth();
        }
        if(localStorage.getItem('locale')){
            locale = localStorage.getItem('locale') ?? LOCALES.RUSSIAN;
        }
        store.setLocale(locale);

    }, []);

    if(store.isLoading){
        return (
            <Loader />
        )
    }

    if(!store.isAuth){
        return (
            <IntlProvider messages={messages[store.locale]} locale={store.locale} defaultLocale={LOCALES.RUSSIAN}>
                <div>
                    <LoginForm  />

                </div>
            </IntlProvider>
        )

    } else {
        return (
            <IntlProvider messages={messages[store.locale]} locale={store.locale} defaultLocale={LOCALES.RUSSIAN}>
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </IntlProvider>

        )
    }

}

export default observer(App)
