import React, {useContext, useEffect, useState} from 'react';
import RepairItem from "./RepairItem";
import Row from "react-bootstrap/Row";
import {useIntl} from "react-intl";
import {Context} from "../index";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import $api from "../http";
import {useNavigate} from "react-router-dom";
import Loader from "./UI/Loader/Loader";
import Col from "react-bootstrap/Col";
import MyButton from "./UI/button/MyButton";



const RepairsList = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const [repairs, setRepairs ] = useState(props.repairsList)
    const [repair, setRepair ] = useState(0)
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [number, setNumber] = useState( '###');
    let navigate = useNavigate();
    const modalClose = () => setShow(false);
    const modalClose2 = () => setShow2(false);
    const modalShow = () => {
        setShow(true);
    }

    const modalShow2 = () => {
        setShow2(true);
    }
    useEffect(() => {
        setRepairs(props.repairsList)
    }, [props.repairsList])

    const acceptRepair = (e) => {
        setRepair(e.target.closest('button').dataset.id)
        setNumber(e.target.closest('button').dataset.number)
        modalShow();

    }

    const deniedRepair = (e) => {
        setRepair(e.target.closest('button').dataset.id)
        setNumber(e.target.closest('button').dataset.number)
        modalShow2();

    }


    const sendAcceptRepair  = async () => {

        if(repair > 0) {
            const response = await $api.get(`/repair/accept?id=${repair}`)
            if(response.status == 200) {
                setRepair(0)
                setRepairs(response.data.repairs)
            }
            modalClose();
        }
    }

    const sendCancelRepair  = async () => {

        if(repair > 0) {
            const response = await $api.get(`/repair/cancel?id=${repair}`)
            if(response.status == 200) {
                setRepair(0)
                setRepairs(response.data.repairs)

            }
            modalClose();
        }
    }

    if (!props.repairsList.length) {
        return (
            <>
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        {intl.messages.repairs_title}
                    </h3>
                </Row>

                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.no_repairs}
                </h3>
                <Row className="mb-3">
                    <Col md={6}>
                        <MyButton variant="primary" to={'/repairs/new'}>{intl.messages.application_insulation}</MyButton>

                    </Col>

                </Row>
            </>
        )
    }

    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.repairs_title}
                </h3>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <MyButton variant="primary" to={'/repairs/new'}>{intl.messages.application_insulation}</MyButton>

                </Col>

            </Row>
            {props.loadzip ? <div className="overblur"><Loader /></div> : null}



                {repairs.map((repair, index) =>

                    <RepairItem key={repair.id} num={index+1} repair={repair}  acceptRepair={acceptRepair} deniedRepair={deniedRepair} downloadPhoto={props.downloadPhoto} />
                )}

            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {intl.messages.agree_estimate} {number} {intl.messages.agree_estimate2}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e)=>sendAcceptRepair()}>
                        {intl.messages.accept}
                    </Button>
                    <Button variant="danger" onClick={(e)=>modalClose()}>
                        {intl.messages.cancel}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={modalClose2}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {intl.messages.reject_container_repair} {number}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e)=>sendCancelRepair()}>
                        {intl.messages.yes}
                    </Button>
                    <Button variant="danger" onClick={(e)=>modalClose2()}>
                        {intl.messages.cancel}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>


    );
};

export default RepairsList;
