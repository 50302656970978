import React, {useContext, useEffect, useState} from 'react';
import ServiceItem from "./ServiceItem";
import Row from "react-bootstrap/Row";
import {useIntl} from "react-intl";
import {Context} from "../index";

import $api from "../http";
import {useNavigate} from "react-router-dom";
import Loader from "./UI/Loader/Loader";
import Col from "react-bootstrap/Col";
import MyButton from "./UI/button/MyButton";



const ServicesList = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const [services, setServices ] = useState(props.servicesList)
    const [service, setService ] = useState(0)
    const [number, setNumber] = useState( '###');
    let navigate = useNavigate();

    useEffect(() => {
        setServices(props.servicesList)
    }, [props.servicesList])

    const acceptRepair = (e) => {
        setService(e.target.closest('button').dataset.id)
        setNumber(e.target.closest('button').dataset.number)


    }

    const deniedRepair = (e) => {
        setService(e.target.closest('button').dataset.id)
        setNumber(e.target.closest('button').dataset.number)


    }


    const sendCancelServices  = async () => {

        if(service > 0) {
            const response = await $api.get(`/service/cancel?id=${service}`)
            if(response.status == 200) {
                setService(0)
                setServices(response.data.services)

            }

        }
    }

    if (!props.servicesList.length) {
        return (
            <>
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        {intl.messages.service_title}
                    </h3>
                </Row>

                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.no_service}
                </h3>
                <Row className="mb-3">
                    <Col md={6}>
                        <MyButton variant="primary" to={'/services/insulation/new'}>{intl.messages.application_insulation}</MyButton>

                    </Col>

                </Row>
            </>
        )
    }

    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.service_title}
                </h3>
            </Row>
            <Row className="mb-3">
                <Col md={6}>
                    <MyButton variant="primary" to={'/services/insulation/new'}>{intl.messages.application_insulation}</MyButton>

                </Col>

            </Row>
            {props.loadzip ? <div className="overblur"><Loader /></div> : null}



                {services.map((service, index) =>

                    <ServiceItem key={service.id} num={index+1} service={service} downloadPhoto={props.downloadPhoto} />
                )}



        </>


    );
};

export default ServicesList;
