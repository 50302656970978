import React, {useContext, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Table} from "react-bootstrap";
import CarRowOutput from "./UI/CarRowOutput";
import $api from "../http";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {useIntl} from "react-intl";

import {useFetching} from "../hooks/useFetching";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Loader from "./UI/Loader/Loader";
import ContainerRowOutputOutCarEdit from "./UI/ContainerRowOutputOutCarEdit";


const ReleaseFormOutFEdit = (props) => {
    const {store} = useContext(Context);
    const [validated, setValidated] = useState(false);
    let navigate = useNavigate();
    const [release, setRelease] = useState(props.release);
    const intl = useIntl();
    const [show, setShow] = useState(false);
    const [containerList, setContainerList] =  useState('');
    const [errorcontainer, setErrorcontainer] = useState([]);
    const [addlist, setAddlist] = useState(false);
    const [save, setSave] = useState(false)
    const [fetchType] = useFetching(async (limit, page) => {
        const response = await $api.get('/containers/type')

        store.setTypeOut(response.data.type);
    })
    let date = new Date();

    const modalClose = () => setShow(false);

    const handleContainerList = (e) => {
        setContainerList(e.target.value)
    }


    const addContainerList = (e) => {
        e.preventDefault();
        let _release = {...release};
        let _containers = [..._release.containers];
        let newContainers = {};
        let str = containerList;
        let array = str.split(/\s*,\s*|\s*\r\s*|\s*\n\s*/)

        let error = [];


        if(typeof array == 'object') {
            //console.log(array);
            //console.log(store.containers);
            for (let i in array) {
                if (array[i].length > 4) {

                    if(store.containers.filter(_c => {
                        return _c.number.toLowerCase().includes(array[i].toLowerCase())
                    }))
                    {
                        if (findDoubleNumber(_containers, array[i])) {
                            newContainers = {type: '', number: `${array[i]}`, car_number: '', fio: '', count: 1};
                            _containers.push(newContainers);
                        }
                    }
                }
            }
        }
        setContainerList('')
        let _newContainers = [];

        for(let i in _containers){

            if(_containers[i].number !== ''){
                _newContainers.push(_containers[i]);
            }
        }
        _release.containers = _newContainers;
        setAddlist(true)
        setRelease(_release);

        if(error.length > 0){
            setErrorcontainer(error)
        } else {
            modalClose()
        }

    }



    const findDoubleNumber = (array, value) => {
        for (let i in array){
            if(array[i].number === value){
                return false;
            }
        }
        return true;
    }
    const calcCountContainer = () => {
        let _release = {...release};
        let _containers = [..._release.containers];
        let count = 0;
        for(let i in _containers){
            count += _containers[i].count * 1
        }

    }
    useEffect(() => {

        fetchType();
        const interval = setInterval(fetchType, 60000);
        return () => clearInterval(interval);
     },[])
    const addNewPost = async (e)  => {
        e.preventDefault()
        setSave(true)
        const response = await $api.post('/release/save', release);
        if(response.status == '200'){

            navigate('/releases');
        } else {
            setSave(false)
        }
    }
    const back = () => {
        navigate('/releases');
    }

    const [rows, setRows] = useState([{type:'', number:'',count:1, car_number:'', fio:''}]);

    const addContainersRow = () => {
        let _release = {...release};
        let _containers = [..._release.containers];
        let newContainers = {type:'', number:'',count:1};
        _containers.push(newContainers);
        _release.containers = _containers;
        setRelease(_release);
        calcCountContainer();
    }
    const addCarsRow = () => {
        let _release = {...release};
        let _cars = [..._release.cars];
        let newCars = {number:'',fio:'',count:1};
        _cars.push(newCars);
        _release.cars = _cars;
        setRelease(_release);
     }

    const handleChangeDelivery = (e) => {
        let car_delivery = 0;
        let car_firm = "";
        if(release.car_delivery == 0){
            car_delivery = 1;
            car_firm = "UNIFLOT";
            e.target.checked = true;
        } else {
            car_delivery = 0
            car_firm = "";
            e.target.checked = false;
        }
        let _release = {...release};
        _release.car_delivery = car_delivery;
        _release.car_firm = car_firm;
        setRelease(_release);
    }
    const rowDelete = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let _release = {...release};
        let _containers = [..._release.containers];
        _containers.splice(index, 1);
        _release.containers = _containers;
        setRelease(_release);
        calcCountContainer();

    }
    const rowDeleteCars = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let _release = {...release};
        let _cars = [..._release.cars];
        _cars.splice(index, 1);
        _release.cars = _cars;
        setRelease(_release);
    }

    const handleChange = (e,name) => {

        let _release = {...release};
        _release[name] = e.target.value;
        setRelease(_release);

    }
    const handleChangeContainers = (value, name, index) => {

        let _release = {...release};
        let _containers = [..._release.containers];
        _containers[index][name] = value;
        _release.containers = _containers;
        setRelease(_release);
        calcCountContainer();

    }

    const handleChangeCars = (value, name, index) => {

        let _release = {...release};
        let _cars = [..._release.cars];
        _cars[index][name] = value;
        _release.cars = _cars;

        setRelease(_release);
    }


    const cancelStatus = async (e) => {
        e.preventDefault();
        let _release = {...release};
        _release.status = 5;
        const response = await $api.post('/release/save', _release);

        if(response.status == '200'){
            navigate('/releases');
        }



    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
        } else {
            setValidated(true);

            addNewPost(e);
        }
    };
    if(save){
        return (
            <Loader />
        )
    }


    return (

        <Form noValidate onSubmit={handleSubmit} className="was-validated">
            <h3 className="my-5">{intl.messages.order_title_out} {(release.id)?release.id : null}:</h3>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label as={Col} sm="3">
                    {intl.messages.issue_terminal}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control plaintext readOnly value={intl.messages[store.user.terminal]} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.date_from}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="date" value={release.date_from} readOnly />

                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateTo">
                <Form.Label column sm="3">
                    {intl.messages.date_to}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="date" value={release.date_to} readOnly />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.release}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" placeholder="" value={release.release} readOnly />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 d-none" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.carrier}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={release.car_firm} readOnly />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.applicant}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={release.user} readOnly />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_email}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="email" value={release.add_email} readOnly />
                </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_information}
                </Form.Label>
                <Col sm="9">
                    <Form.Control as="textarea" value={release.description} readOnly rows={3} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.containers_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{intl.messages.type}</th>
                                <th>{intl.messages.number_container}</th>
                                <th>{intl.messages.quantity}</th>


                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {release.containers.map((row, index) =>
                                <ContainerRowOutputOutCarEdit key={index}
                                                   index={index}
                                                   row={row}
                                                   car_firm={release.car_firm}
                                                   handleChange = {handleChangeContainers}
                                                   delete={rowDelete}
                                                   addlist={addlist}
                                                   del={release.containers.length > 1 ? true: false}
                                />
                            )}

                            </tbody>
                        </Table>
                    </Row>

                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.car_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{intl.messages.number}</th>
                                <th>{intl.messages.driver}</th>
                                <th>{intl.messages.quantity}</th>


                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {release.cars.map((row, index) =>
                                <CarRowOutput key={index}
                                                          index={index}
                                                          row={row}
                                                          status={release.status}
                                                          handleChange = {handleChangeCars}
                                                          delete={rowDeleteCars}
                                                          del={release.cars.length > 1 ? true: false}
                                />

                            )}

                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mb-3" >
                        <Col sm={{span: 3}}>
                            <Button variant="secondary" onClick={addCarsRow}>{intl.messages.add_auto}</Button>
                        </Col>

                    </Row>
                </Col>
            </Form.Group>

            <Row className="mb-3 text-align-right" >
                <Col className="d-grid gap-2 d-md-block" >
                    <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                    {release.id ? <Button className="mx-md-1 btn-danger " type={"button"} onClick={(e)=>cancelStatus(e)}>{intl.messages.cancel}</Button>: ''}
                    <Button className="mx-md-1" type={"submit"}>{release.id?intl.messages.save:intl.messages.create}</Button>
                </Col>

            </Row>
            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.messages.list_containers}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control id="addContainer" as="textarea" value={containerList} onChange={(e)=>handleContainerList(e)}  rows={6} />
                    <Row className="mt-3 text-align-right">
                        <Col className="mb-3 d-grid gap-2 d-md-block" >
                            <Alert variant="info">

                                {intl.messages.list_containers_message}</Alert>
                            {errorcontainer.length > 0 ?
                                <Alert  variant="danger"> <Alert.Heading>{intl.messages.incorrect_number}</Alert.Heading>
                                    {errorcontainer.join(', ')}</Alert>
                                : null
                            }
                        </Col>


                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e)=>addContainerList(e)}>
                        {intl.messages.add}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>

    );
};

export default ReleaseFormOutFEdit;
