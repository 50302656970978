import React from 'react';
import MovingItem from "./MovingItem";
import Row from "react-bootstrap/Row";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import Icon from "@reacticons/bootstrap-icons";
import Col from "react-bootstrap/Col";

const MovingsList = (props) => {
    const intl = useIntl();
    const downloadImport = () => {

    }
    const downloadExport = () => {

    }

    if (!props.movingsList.length) {
        return (
            <>
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        {intl.messages.movings_title}
                    </h3>
                </Row>

                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.no_movings}
                </h3>
            </>
        )
    }

    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.movings_title}
                </h3>
                <Col style={{textAlign: 'right'}}>
                    <Icon className="dublFont" name="filetype-xlsx" title={intl.messages.import}  onClick={downloadImport} />
                    <Icon className="dublFont" name="filetype-xlsx" title={intl.messages.export}  onClick={downloadExport} />
                </Col>
            </Row>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{intl.messages.action}</th>
                    <th>{intl.messages.documents}</th>
                    <th>{intl.messages.container}</th>
                    <th>{intl.messages.type}</th>
                    <th>{intl.messages.date}</th>
                    <th>{intl.messages.stock}</th>
                    <th>{intl.messages.order}</th>
                    <th>{intl.messages.car}</th>
                    <th>{intl.messages.driver}</th>
                    <th>{intl.messages.release}</th>
                    <th>{intl.messages.comment}</th>


                </tr>
                </thead>
                <tbody>


                {props.movingsList.map((moving, index) =>

                    <MovingItem key={moving.id} moving={moving}/>
                )}
                </tbody>
            </Table>


        </>


    );
};

export default MovingsList;
