import React, {useContext} from 'react';
import {getPagesArray} from "../../utils/pages";
import Pagination from 'react-bootstrap/Pagination';
import Form from "react-bootstrap/Form";
import {useIntl} from "react-intl";
import {Context} from "../../index";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Paginator = ({totalPages, page, changePage, paginatorCount, limit}) => {
    const {store} = useContext(Context);
    const intl = useIntl();

    let pagesArray = getPagesArray(totalPages);
    return (
        <Row>
            <Col md={6}>
                <label style={{float: 'left', lineHeight: '30px'}}>{intl.messages.show}: </label>
                <Form.Select value={limit} style={{width: '80px'}}  size="sm" onChange={(e) => paginatorCount(e)}>
                    <option value="25" >25</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>
                    <option value="999999999" >{intl.messages.all}</option>
                </Form.Select>
            </Col>
            <Col md={6}>
                <Pagination>
                    { page === 1 ? <>
                            <Pagination.First disabled />
                            <Pagination.Prev disabled />
                        </>
                        : <>
                            <Pagination.First onClick={() => changePage(1)} />
                            <Pagination.Prev onClick={() => changePage(page-1)} />
                        </>
                    }
                    {totalPages > 5 ?
                        page === totalPages ? <>
                                <Pagination.Ellipsis />
                                <Pagination.Item  onClick={() => changePage(page - 3)} >{page - 3}</Pagination.Item>
                                <Pagination.Item  onClick={() => changePage(page - 2)} >{page - 2}</Pagination.Item>
                                <Pagination.Item  onClick={() => changePage(page - 1)} >{page - 1}</Pagination.Item>
                                <Pagination.Item  onClick={() => changePage(page)} active>{page}</Pagination.Item>
                            </>
                            : page === totalPages - 1 ? <>
                                    <Pagination.Ellipsis />
                                    <Pagination.Item  onClick={() => changePage(page - 2)} >{page - 2}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(page - 1)} >{page - 1}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(page)} active >{page}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(page+1)} >{page+1}</Pagination.Item>
                                </>
                                : page > 2 ?  <>
                                    <Pagination.Ellipsis />
                                    <Pagination.Item  onClick={() => changePage(page - 1)} >{page - 1}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(page)} active>{page}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(page + 1)} >{page + 1}</Pagination.Item>
                                    <Pagination.Ellipsis />
                                </>  : page === 2 ? <>
                                    <Pagination.Item  onClick={() => changePage(1)} >{1}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(2)} active >{2}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(3)} >{3}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(4)} >{4}</Pagination.Item>
                                    <Pagination.Ellipsis />
                                </> : <>
                                    <Pagination.Item  onClick={() => changePage(1)} active >{1}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(2)} >{2}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(3)} >{3}</Pagination.Item>
                                    <Pagination.Item  onClick={() => changePage(4)} >{4}</Pagination.Item>
                                    <Pagination.Ellipsis />
                                </>

                        :
                        pagesArray.map(p =>
                            page === p ?
                                <Pagination.Item  onClick={() => changePage(p)} active>{p}</Pagination.Item>:
                                <Pagination.Item  onClick={() => changePage(p)} >{p}</Pagination.Item>
                        )
                    }
                    { page === totalPages ?<>
                            <Pagination.Next disabled />
                            <Pagination.Last disabled /> </>:
                        <>
                            <Pagination.Next onClick={() => changePage(page+1)} />
                            <Pagination.Last onClick={() => changePage(totalPages)} />
                        </>
                    }

                </Pagination>
            </Col>
        </Row>


    );
};

export default Paginator;
