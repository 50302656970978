import React from 'react';
import Datelocale from "./UI/Datelocale";
import {useIntl} from "react-intl";
import Icon from "@reacticons/bootstrap-icons";

const BillItem = (props) => {
    const intl = useIntl();

    return (
        <><tr >
            <td>{props.num}</td>
            <td>{props.bill.bill_number}</td>
            <td><Datelocale date={props.bill.bill_datetime} /></td>
            <td>{props.bill.bill_amount} {props.bill.currency_code}</td>
            <td>{intl.messages[props.bill.bill_payment_status]}</td>
            <td><Icon className="dublFont" name="filetype-xlsx" title={intl.messages.bill_registry} data-id={props.bill.currensy_bill} onClick={props.downloadReestr} /> <Icon className="dublFont" name="filetype-pdf" title={intl.messages.bill} data-id={props.bill.currensy_bill} onClick={props.downloadBill} /></td>
            <td></td>
        </tr>
        </>
    );
};

export default BillItem;
