import React, {useState} from 'react';
import Fancybox from "./Fancybox";
import Form from "react-bootstrap/Form";

const BrakRow = (props) => {

    const handleChange = (e) => {
        let _val = {value:e.target.value, field:'ucenka',index:props.index, id:e.target.dataset.id}
        props.saveUcenka(_val)

    }
    return (
        <tr >
            <td>{props.index + 1}.</td>
            <td>{props.brak.gruz}</td>
            <td>{props.brak.count}</td>
            <td>{props.brak.comment}</td>
            <td>{props.brak.serials}</td>
            <td><Form.Control type="text" data-id={props.brak.id} value={props.brak.ucenka} onChange={e => handleChange(e)}  /></td>
            <td><Fancybox>
                {props.brak.photos.map((photo, ind) =>

                    <a key={ind} className="mx-2" data-fancybox="gallery"
                       data-download-src={'https://containera.online/app' + photo.patch}
                       href={'https://containera.online/app' + photo.patch}>Фото{ind + 1}</a>
                )}</Fancybox>
            </td>
        </tr>
    )
}

export default BrakRow;