import React from 'react';
import cl from './Loader.module.css';

const Loader = () => {
    return (
        <div className={cl.center_block}>
        <div className={cl.roller}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </div>
    );
};

export default Loader;
