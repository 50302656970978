import React from 'react';
import {useNavigate} from 'react-router-dom';
import Datelocale from "./UI/Datelocale";
import {useIntl} from "react-intl";
import Badge from 'react-bootstrap/Badge';
import Icon from "@reacticons/bootstrap-icons";

const ReleaseItemDNS = (props) => {
    const intl = useIntl();
    const status = { 1: intl.messages.new,2:intl.messages.agreed, 3:intl.messages.in_work,4:intl.messages.closed,5:intl.messages.denied,6:intl.messages.canceled,7:intl.messages.overdue}
    const type = {'IN':intl.messages.import, 'OUT':intl.messages.export, "RELOAD-S":intl.messages.reload_store,"RELOAD":intl.messages.reload,"OUT_COMEBACK":'Вывоз и возврат'}
    const navigate = useNavigate();
    const clickRow = (e) => {

        let link = "/release/" + e.target.innerText
        navigate(link)
    }
    const viewRow = (e) => {

        let link = "/release/view/" + e.target.innerText
        navigate(link)
    }
    if(props.post.status < 3) {
        return (
            <>
                <tr
                    className={(props.post.type_release == 'IN') ? 'table-success' : (props.post.type_release == 'OUT') ? 'table-danger' :(props.post.type_release == 'OUT_COMEBACK') ? 'table-warning': 'table-info'}>
                    <td rowspan={props.post.rows.length + 1} className="linkTd" onClick={clickRow} data-link={props.post.id}>{props.post.id}</td>
                    <td rowspan={props.post.rows.length + 1}>{type[props.post.type_release]}</td>
                    <td rowspan={props.post.rows.length + 1}><Datelocale date={props.post.date_from}/></td>
                    <td rowspan={props.post.rows.length + 1}><Datelocale date={props.post.date_to}/></td>
                    <td>{props.post.slot}{props.post.compleet}
                    </td>

                    <td className="containerCol">{props.post.number}{props.post.com}
                    </td>
                    <td>{props.post.gruz}</td>
                    <td>{props.post.artikul}</td>
                    <td rowspan={props.post.rows.length + 1}>{props.post.ready}</td>
                    <td rowspan={props.post.rows.length + 1}>{props.post.release}</td>
                    <td rowspan={props.post.rows.length + 1}>{props.post.user}</td>
                    <td rowspan={props.post.rows.length + 1}>{status[props.post.status]}</td>

                </tr>
                {props.post.rows.length>0?
                <>
                    {props.post.rows.map((p, index) =>
                        <tr key={index} className={(props.post.type_release == 'IN') ? 'table-success' : (props.post.type_release == 'OUT') ? 'table-danger' :(props.post.type_release == 'OUT_COMEBACK') ? 'table-warning': 'table-info'}>
                            <td>{p.slot}
                            </td>

                            <td className="containerCol">

                                {p.number}
                                {p.com}
                            </td>
                            <td>{p.gruz}</td>
                            <td>{p.artikul}</td>
                        </tr>
                    )}

                </> :
                    null}
            </>
        );
    } else {
        return (
            <>
                <tr className={(props.post.type_release == 'IN') ? 'table-success' : (props.post.type_release == 'OUT') ? 'table-danger' : (props.post.type_release == 'OUT_COMEBACK') ? 'table-warning' : 'table-info'}>
                    <td rowspan={props.post.rows.length + 1} className="linkTd" onClick={viewRow}
                        data-link={props.post.id}>{props.post.id}</td>
                    <td rowspan={props.post.rows.length + 1}>{type[props.post.type_release]}</td>
                    <td rowspan={props.post.rows.length + 1}><Datelocale date={props.post.date_from}/></td>
                    <td rowspan={props.post.rows.length + 1}><Datelocale date={props.post.date_to}/></td>
                    <td>{props.post.slot}
                    </td>

                    <td className="containerCol">
                            {props.post.number}
                        {props.post.com}
                    </td>
                    <td>{props.post.gruz}</td>
                    <td>{props.post.artikul}</td>
                    <td rowspan={props.post.rows.length + 1}>{props.post.ready}</td>
                    <td rowspan={props.post.rows.length + 1}>{props.post.release}</td>
                    <td rowspan={props.post.rows.length + 1}>{props.post.user}</td>
                    <td rowspan={props.post.rows.length + 1}>{status[props.post.status]}</td>
                </tr>
                {props.post.rows.length>0?
                    <>
                        {props.post.rows.map((p, index) =>
                            <tr key={index} className={(props.post.type_release == 'IN') ? 'table-success' : (props.post.type_release == 'OUT') ? 'table-danger' :(props.post.type_release == 'OUT_COMEBACK') ? 'table-warning': 'table-info'}>
                                <td>{p.slot}
                                </td>

                                <td className="containerCol">

                                    {p.number}
                                    {p.com}
                                </td>
                                <td>{p.gruz}</td>
                                <td>{p.artikul}</td>
                            </tr>
                        )}

                    </> :
                    null}
            </>
        );
    }
};

export default ReleaseItemDNS;
