import React from 'react';
import Row from "react-bootstrap/Row";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import $api from "../http";
import BillItem from "./BillItem";



const BillsList = (props) => {
    const intl = useIntl();
    const downloadBill = async (e) => {
            let id = e.target.closest('div').dataset.id
           await $api.get(`/bills/downloadbill?id=${id}`, {responseType: 'blob'})
               .then(res=> {
               const url = window.URL.createObjectURL(res.data);
               const link = document.createElement("a");
               link.href = url;
               link.setAttribute("download", `bill_${id}.pdf`);
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
           })


    }
    const downloadReestr = async (e) => {
        let id = e.target.closest('div').dataset.id
        await $api.get(`/bills/download?id=${id}`, {responseType: 'blob'})
            .then(res=> {
                const filename = res.headers["cache-control"];
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })


    }

    if (!props.billsList.length) {
        return (
            <>
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        {intl.messages.bills_title}
                    </h3>
                </Row>

                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.no_bills}
                </h3>
            </>
        )
    }

    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.bills_title}
                </h3>
            </Row>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{intl.messages.bill_number}</th>
                    <th>{intl.messages.date}</th>
                    <th>{intl.messages.bill_amount}</th>
                    <th>{intl.messages.bill_payment_status}</th>
                    <th>{intl.messages.bill_download}</th>
                    <th></th>


                </tr>
                </thead>
                <tbody>


                {props.billsList.map((bill, index) =>

                    <BillItem key={bill.id} num={index+1} bill={bill} downloadBill={downloadBill} downloadReestr={downloadReestr}/>
                )}
                </tbody>
            </Table>

        </>


    );
};

export default BillsList;
