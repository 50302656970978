import React from 'react';
import {Outlet} from "react-router-dom";
import Navigation from "./Navigation";
import Container from 'react-bootstrap/Container';

const Layout = () => {
    return (
        <>
            <Navigation />
            <Container className="table-responsive">
                <Outlet />
            </Container>


        </>
    );
}

export default Layout;