import React, {useContext, useEffect, useState} from 'react';
import RepairsList from "../components/RepairsList";
import $api from "../http";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/UI/Loader/Loader";
import {Context} from "../index";



function Repairs () {
    const {store} = useContext(Context);
    const [repairs, setRepairs] = useState([]);
    const [loadzip, setLoadzip] = useState(0);
    const [fetchRepairs, isPostsLoading, postError] = useFetching(async (limit, page) => {
        const response = await $api.get('/repairs')

        if(response.status == 200) {
            setRepairs(response.data.repairs);

        } else {
            store.logout()
        }
    })

    const downloadPhoto = async (e) => {
        let id = e.target.dataset.id;
        let name = e.target.dataset.number + '.zip'
        setLoadzip(1)
        const response = await $api.get(`/repairs/downloadPhoto?id=${id}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoadzip(0)
            })


    }
    const agreedRepair = async (e) => {
        if(window.confirm('Вы хотите заблокировать контейнер?')) {
            let id = e.target.closest('div').dataset.id
            await $api.get(`/repairs/agree/${id}`)
            fetchRepairs();
        }
    }


    useEffect(() => {
        fetchRepairs();
        const interval = setInterval(fetchRepairs, 120000);
        return () => clearInterval(interval);
    }, [])

    if(isPostsLoading && repairs.length == 0) {
        return (
            <Loader />
        );
    }  else {

        return (
            <div className="App">
                <RepairsList repairsList={repairs} agreedRepair={agreedRepair} downloadPhoto={downloadPhoto} loadzip={loadzip} />
            </div>
        );
    }
}

export default Repairs;
