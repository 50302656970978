import React, {useContext, useEffect} from 'react';
import {Context} from "../index";
import {useNavigate} from 'react-router-dom';

const Dashboard = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/containers')
    }, [])

        return (
         <></>
        );


};

export default Dashboard;
