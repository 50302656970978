import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useFetching} from "../hooks/useFetching";
import $api from "../http";
import Loader from "../components/UI/Loader/Loader";
import {Context} from "../index";
import ContainerFormBig from "../components/ContainerFormBig";
import ContainerForm from "../components/ContainerForm";

const Container = () => {
    const {store} = useContext(Context);
    const {id} = useParams();
    const [container, setContainer] = useState([]);
    const [fetchContainer, isConteinerLoading] = useFetching(async () => {
        const response = await $api.get(`/container/getById/${id}`)

        setContainer(response.data.container);

    })

    const saveUcenka = async (value)  => {


        const response = await $api.post('/container/save_brack_rows', value);
        if(response.status == '200'){
            return handleChangeContainers(value);
        } else {
            alert('Уценка не сохранилась')
            return '';
        }
    }

    const handleChangeContainers = (value) => {
        let _container = {...container};
        let _brak_rows = [..._container.brak_row];
        _brak_rows[value.index][value.field] = value.value;
        _container.brak_row = _brak_rows;
        setContainer(_container);

    }

    const handleSaveMovi = async (value) => {
        const response = await $api.post('/container/save_move', value);
        if(response.status == '200'){
            return handleChangeMovi(value);
        } else {
            alert('Уценка не сохранилась')
            return '';
        }

    }

    const handleChangeMovi = (value) => {
        let _container = {...container};
        _container[value.field] = value.value;
        setContainer(_container);

    }

    useEffect(() => {
        fetchContainer()
    }, [])


    if(isConteinerLoading){
        return(
         <Loader />
        )
    }
    else if(container.unload > 0){
        return (
                <ContainerFormBig container={container} saveUcenka={saveUcenka} handleSaveMovi={handleSaveMovi} />

            );
        } else {
        return (
            <ContainerForm container={container} handleSaveMovi={handleSaveMovi}/>

        );
    }


};

export default Container;
