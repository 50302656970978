import React, {useState} from 'react';
import Form from "react-bootstrap/Form";


const CarRowOutputEdit = (props) => {
    const row = props.row;
    const handleChange = (e,name) => {
        props.handleChange(e,name, props.index);

    }

    return (
        <tr>
            <td>

            </td>
            <td>
                <Form.Control type="text" disabled value={row.number}  required />
            </td>


            <td>
                <Form.Control disabled value={row.fio}  type="text" placeholder="" />
            </td>
            <td>
                <Form.Control value={row.phone} onChange={e => handleChange(e, 'phone')} type="text" placeholder="" />
            </td>
            <td>
                <Form.Control disabled value={row.count}  type="text" placeholder="" required />
            </td>
            <td>

            </td>
        </tr>
    );
}

export default CarRowOutputEdit;