import React from 'react';
import DateTimelocale from "./UI/DateTimelocale";
import Doclist from "./UI/Doclist";

const MovingItem = (props) => {

    const type_moving = {IN:"Завоз",OUT:"Вывоз"}
/*    const clickRow = (e) => {

        let link = "/release/" + e.target.closest("tr").dataset.link
        navigate(link)
    }
*/
    const setDocs = () => {
        let doc = [];
        if(props.moving.patch_act){
            doc.push(props.moving.patch_act)
        }
        if(props.moving.patch_ttn){
            doc.push(props.moving.patch_ttn)
        }
        if(props.moving.patch_front){
            doc.push(props.moving.patch_front)
        }
        if(props.moving.patch_left){
            doc.push(props.moving.patch_left)
        }
        if(props.moving.patch_right){
            doc.push(props.moving.patch_right)
        }
        if(props.moving.patch_rear){
            doc.push(props.moving.patch_rear)
        }

        if(props.moving.photo != null && props.moving.photo.length > 0 ){
            for(let r in props.moving.photo){
                doc.push(props.moving.photo[r])
            }
        }
        return doc;

    }

    return (
        <><tr >
            <td>{props.moving.id}</td>
            <td>{type_moving[props.moving.moving_type]}</td>
            <td>
                <Doclist photo={setDocs()} />
            </td>
            <td>{props.moving.number}</td>
            <td>{props.moving.type}</td>
            <td><DateTimelocale date={props.moving.created} /></td>
            <td>{props.moving.stock}</td>
            <td>{props.moving.release_id}</td>
            <td>{props.moving.car_number}</td>
            <td>{props.moving.car_driver}</td>
            <td>{props.moving.release}</td>
            <td>{props.moving.comment}</td>


        </tr>
        </>
    );
};

export default MovingItem;
