import React, {useContext, useState} from 'react';

import Row from "react-bootstrap/Row";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";

import {Context} from "../index";
import DepotItem from "./UI/DepotItem";




const DepotList = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const [pfilter, setPfilter] = useState({gruz:'',artikul:'',serials:'',container_upload:'',ucenka:'',comment:''});
    const handleChangeFilter = (e, name) => {
        let _filter = {...pfilter}
        _filter[name] = e.target.value
        setPfilter(_filter)
        props.handleFilter(_filter);

    }

    return (
        <>


            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Наименование</th>
                    <th>Артикул</th>
                    <th>Серийный номер</th>
                    <th>из Контейнера</th>
                    <th>Повреждения</th>
                    <th>Уценка</th>
                    <th width="300px">Фото</th>
                </tr>
                <tr>
                    <td></td>
                    <td><input className="form-control" value={pfilter.gruz} type="text"
                               onChange={(e) => handleChangeFilter(e, 'gruz')}/></td>
                    <td><input className="form-control" value={pfilter.artikul} type="text"
                               onChange={(e) => handleChangeFilter(e, 'artikul')}/></td>

                    <td><input className="form-control" value={pfilter.serials} type="text"
                               onChange={(e) => handleChangeFilter(e, 'serials')}/></td>
                    <td><input className="form-control" value={pfilter.container_upload} type="text"
                               onChange={(e) => handleChangeFilter(e, 'container_upload')}/></td>
                    <td><input className="form-control" value={pfilter.comment} type="text"
                               onChange={(e) => handleChangeFilter(e, 'comment')}/></td>
                    <td><input className="form-control" value={pfilter.ucenka} type="text"
                               onChange={(e) => handleChangeFilter(e, 'ucenka')}/></td>
                    <td></td>
                </tr>

                </thead>
                <tbody>


                {props.depotList.map((depot, index) =>

                    <DepotItem key={index} num={index} depot={depot}/>
                )}


                <tr>
                    <td></td>
                    <td><strong>Итого:</strong></td>
                    <td><strong>{props.totalItem}</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </Table>

        </>


    );
};

export default DepotList;
