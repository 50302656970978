import React from 'react';

import Icon from "@reacticons/bootstrap-icons";
import Fancybox from "./Fancybox";

const Doclist = (props) => {

    const printlink = (el, index) => {
        let _ext = ext(el)
        if(index == 0){
            return whithIcon(el,_ext)
        } else {
            return whithOutIcon(el,_ext)
        }
    }

    const whithIcon = (el,_ext) => {
        if(_ext == 'pdf'){
            return (
                <a data-fancybox href={el}>
                    <Icon name="images" />
                </a>
            )
        } else {
            return (
                <a data-fancybox="gallery" href={el}>
                    <Icon name="images" />
                </a>
            )
        }
    }
    const whithOutIcon = (el,_ext) => {
        if(_ext == 'pdf'){
            return (
                <a data-fancybox href={el}>

                </a>
            )
        } else {
            return (
                <a data-fancybox="gallery" href={el}>

                </a>
            )
        }
    }

    const ext = (name) => {
        let m = name.match(/\.([^.]+)$/)
        return m && m[1]
    }

    if(props.photo.length > 0){
        return (

                    <Fancybox>
                        {props.photo.map((el, index) =>
                                index < 1 ?
                                    <a key={index} data-fancybox="gallery" data-download-src={el} href={el}>
                                       Photo
                                    </a> :
                                    <a key={index} data-fancybox="gallery" data-download-src={el} href={el}>

                                    </a>




                        )}
                    </Fancybox>


        );
    } else {
        return (<></>)
    }



}

export default Doclist;