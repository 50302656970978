import React, {useContext, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import {Context} from "../../index";

const ContainerRowOutputEdit = (props) => {
    const {store} = useContext(Context);
    const row = props.row;
    const [gvalid, setGvalid] = useState(true);
    let groupValid = true;
    useEffect(() => {

        if (props.row.type !== '') {
            groupValid = false;
        }
        if (props.row.number !== '') {
            groupValid = false;
        }
        setGvalid(groupValid);
    }, [])
    const handleChange = (e, name) => {
        props.handleChange(e, name, props.index);
        groupValid = true;

        if (props.row.type !== '') {
            groupValid = false;
        }
        if (props.row.number !== '') {
            groupValid = false;
        }
        setGvalid(groupValid);
    }


    const type = store.type;

    return (
        <tr>
            <td>

            </td>

            <td>

                    <Form.Select disabled value={row.type} onChange={e => handleChange(e, 'type')}>
                        <option></option>
                        {type.map((s, i) =>
                            <option key={i} value={s.id}>{s.name}</option>
                        )}
                    </Form.Select>


            </td>
            <td>
                <Form.Control autoComplete="off"  value={row.number} readOnly type="text"
                              placeholder=""/>
            </td>
            <td>
                <Form.Control  value={row.count} type="number" readOnly  />
            </td>
            <td>
                <Form.Control readOnly type="text" value={row.car_number} onChange={e => handleChange(e, 'car_number')} required />


            </td>
            <td>
                <Form.Control readOnly value={row.fio} onChange={e => handleChange(e, 'fio')} type="text" placeholder="" required />
            </td>
            <td>

            </td>
        </tr>
    );
}

export default ContainerRowOutputEdit;