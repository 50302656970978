import React, {useContext, useEffect, useState} from 'react';

import {Context} from "../index";
import InsulationForm from "../components/InsulationForm";



function Insulation () {
    const {store} = useContext(Context);




        return (
            <div className="App">
                <InsulationForm  />
            </div>
        );

}

export default Insulation;
