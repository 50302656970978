import React, {useContext, useState} from 'react';
import MovingItem2 from "./MovingItem2";
import Row from "react-bootstrap/Row";
import {Table} from "react-bootstrap";
import {useIntl} from "react-intl";
import {Context} from "../index";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Paginator from "./UI/Paginator";
import Loader from "./UI/Loader/Loader";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Button from "react-bootstrap/Button";
import Icon from "@reacticons/bootstrap-icons";
import $api from "../http";



const MovingsList2 = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const [pfilter, setPfilter] = useState({number:'',state:'',content:'',car_driver:"",data_in:"",data_out:"",release_id:"",car_number:"",prelease_id:"", pcar_driver:"",pcar_number:"", release:"" });
    const [show2, setShow2] = useState(false);
    let state = [{title:intl.messages.intact, value:"A"}, {title:intl.messages.damaged, value:"U"}, {title:intl.messages.refurbished, value:"UA"}, {title:intl.messages.refused_to_refurbish, value:"WR"}]
    let content = [{title:intl.messages.empty, value:"E"}, {title:intl.messages.fullc, value:"F"}]
    const handleChangeFilter = (e, name) => {
        let _filter = {...pfilter}
        _filter[name] = e.target.value
        setPfilter(_filter)
        props.handleFilter(_filter);
    }
    const modalClose2 = () => setShow2(false);
    const modalShow2 = () => {
        setShow2(true);
    }

    const downloadRelease = async (e) => {
        let locale = store.locale;
        let daterange = document.querySelector('#daterange').value
        await $api.get(`/movings/report?locale=${locale}&date=${daterange}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `report-movings-${daterange}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                modalClose2();
            })


    }

    if (!props.movingsList.length) {
        return (
            <>
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        {intl.messages.movings_title}
                    </h3>
                </Row>

                <Table striped bordered hover>
                    <thead>
                    <tr>

                        <th rowSpan={2}>{intl.messages.container}</th>
                        <th rowSpan={2}>{intl.messages.state}</th>
                        <th rowSpan={2}>{intl.messages.content}</th>
                        <th colSpan={5}>{intl.messages.m_in}</th>
                        <th rowSpan={2} width="50px">{intl.messages.days}</th>
                        <th colSpan={6}>{intl.messages.m_out}</th>

                        <th rowSpan={2}></th>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>{intl.messages.date}</th>
                        <th>{intl.messages.car}</th>
                        <th>{intl.messages.driver}</th>

                        <th>{intl.messages.photo}</th>
                        <th>#</th>
                        <th>{intl.messages.release}</th>
                        <th>{intl.messages.date}</th>
                        <th>{intl.messages.car}</th>
                        <th>{intl.messages.driver}</th>
                        <th>{intl.messages.photo}</th>
                    </tr>
                    <tr>
                        <th><input className="form-control" value={pfilter.number} type="text" onChange={(e) => handleChangeFilter(e, 'number')}/></th>
                        <th><Form.Select value={pfilter.state} onChange={(e) => handleChangeFilter(e, 'state')} >
                            <option value=""> - </option>
                            {state.map((s, i) =>
                                <option key={i} value={s.value}>{s.title}</option>
                            )}
                        </Form.Select></th>
                        <th><Form.Select value={pfilter.content} onChange={(e) => handleChangeFilter(e, 'content')} >
                            <option value=""> - </option>
                            {content.map((s, i) =>
                                <option key={i} value={s.value}>{s.title}</option>
                            )}
                        </Form.Select></th>
                        <th></th>

                        <th></th>
                        <th><input className="form-control" value={pfilter.car_number} type="text" onChange={(e) => handleChangeFilter(e, 'car_number')}/></th>

                        <th><input className="form-control" value={pfilter.car_driver} type="text" onChange={(e) => handleChangeFilter(e, 'car_driver')}/></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th><input className="form-control" value={pfilter.pcar_number} type="text" onChange={(e) => handleChangeFilter(e, 'pcar_number')}/></th>

                        <th><input className="form-control" value={pfilter.pcar_driver} type="text" onChange={(e) => handleChangeFilter(e, 'pcar_driver')}/></th>

                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr><td style={{textAlign: 'center'}} colSpan={16}>{intl.messages.no_movings}</td></tr>

                    </tbody>
                </Table>
            </>
        )
    }

    return (
        <>
            <Row className="my-3">
                <h3 style={{textAlign: 'center'}}>
                    {intl.messages.movings_title}
                </h3>
            </Row>
            <Row>
                <Col md={6} >

                </Col>
                <Col md={6} style={{textAlign: 'right'}}>
                    <button type="button" className="btn btn-success" onClick={modalShow2} ><Icon className="dublFont" name="filetype-xlsx" title={intl.messages.download}  /> {intl.messages.download}</button>
                </Col>
            </Row>
            <Row>
                <Col className="col-12" >
                    {props.isMovingsLoading && store.movings.length === 0 ? <Loader /> :
                        <Paginator totalPages={props.totalPage} page={props.page} changePage={props.changePage} paginatorCount={props.handlePaginatorCount} limit={props.limit} />
                    }
                </Col>

            </Row>
            <Table striped bordered hover>
                <thead>
                <tr>

                    <th rowSpan={2}>{intl.messages.container}</th>
                    <th rowSpan={2}>{intl.messages.state}</th>
                    <th rowSpan={2}>{intl.messages.content}</th>
                    <th colSpan={5}>{intl.messages.m_in}</th>
                    <th rowSpan={2} width="50px">{intl.messages.days}</th>
                    <th colSpan={6}>{intl.messages.m_out}</th>

                    <th rowSpan={2}></th>
                </tr>
                <tr>
                    <th>#</th>
                    <th>{intl.messages.date}</th>
                    <th>{intl.messages.car}</th>
                    <th>{intl.messages.driver}</th>

                    <th>{intl.messages.photo}</th>
                    <th>#</th>
                    <th>{intl.messages.release}</th>
                    <th>{intl.messages.date}</th>
                    <th>{intl.messages.car}</th>
                    <th>{intl.messages.driver}</th>
                    <th>{intl.messages.photo}</th>
                </tr>
                <tr>
                    <th><input className="form-control" value={pfilter.number} type="text" onChange={(e) => handleChangeFilter(e, 'number')}/></th>
                    <th><Form.Select value={pfilter.state} onChange={(e) => handleChangeFilter(e, 'state')} >
                        <option value=""> - </option>
                        {state.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}
                    </Form.Select></th>
                    <th><Form.Select value={pfilter.content} onChange={(e) => handleChangeFilter(e, 'content')} >
                        <option value=""> - </option>
                        {content.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}
                    </Form.Select></th>
                    <th></th>

                    <th></th>
                    <th><input className="form-control" value={pfilter.car_number} type="text" onChange={(e) => handleChangeFilter(e, 'car_number')}/></th>

                    <th><input className="form-control" value={pfilter.car_driver} type="text" onChange={(e) => handleChangeFilter(e, 'car_driver')}/></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><input className="form-control" value={pfilter.pcar_number} type="text" onChange={(e) => handleChangeFilter(e, 'pcar_number')}/></th>

                    <th><input className="form-control" value={pfilter.pcar_driver} type="text" onChange={(e) => handleChangeFilter(e, 'pcar_driver')}/></th>

                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>


                {props.movingsList.map((moving, index) =>

                    <MovingItem2 key={moving.id} moving={moving}/>
                )}
                </tbody>
            </Table>
            <Row>
                <Col className="col-12" >
                    {props.isMovingsLoading? null :
                        <Paginator totalPages={props.totalPage} page={props.page} changePage={props.changePage} paginatorCount={props.handlePaginatorCount} limit={props.limit} />
                    }
                </Col>

            </Row>
            <Modal show={show2} onHide={modalClose2}>
                <Modal.Header closeButton>
                    <h4>
                        {intl.messages.generate_report} :
                    </h4>
                </Modal.Header>
                <Modal.Body> <label>{intl.messages.range}:</label>
                    <DateRangePicker initialSettings={{ startDate: '05/01/2023' }}>

                        <input type="text" id='daterange' className="form-control" />
                    </DateRangePicker>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={(e)=>downloadRelease()}>
                        {intl.messages.download}
                    </Button>
                    <Button variant="danger" onClick={(e)=>modalClose2()}>
                        {intl.messages.canceled}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>


    );
};

export default MovingsList2;
