import React, {useContext, useEffect, useState} from 'react';
import ServicesList from "../components/ServicesList";
import $api from "../http";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/UI/Loader/Loader";
import {Context} from "../index";



function Services () {
    const {store} = useContext(Context);
    const [services, setServices] = useState([]);
    const [loadzip, setLoadzip] = useState(0);

    const [fetchServices, isPostsLoading] = useFetching(async (limit, page) => {
        const response = await $api.get('/services')

        if(response.status == 200) {
            setServices(response.data.services);

        } else {
            store.logout()
        }
    })

    const downloadPhoto = async (e) => {
        let id = e.target.dataset.id;
        let name = e.target.dataset.number + '.zip'
        setLoadzip(1)
        await $api.get(`/repairs/downloadPhoto?id=${id}`, {responseType: 'blob'})
            .then(res=> {
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoadzip(0)
            })


    }



    useEffect(() => {
        fetchServices();
        const interval = setInterval(fetchServices, 120000);
        return () => clearInterval(interval);
    }, [])

    if(isPostsLoading && services.length == 0) {
        return (
            <Loader />
        );
    }  else {

        return (
            <div className="App">
                <ServicesList servicesList={services} downloadPhoto={downloadPhoto} loadzip={loadzip} />
            </div>
        );
    }
}

export default Services;
