import React, {FC, useContext, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Context} from "../index";
import '../css/login.css';
import {observer} from "mobx-react-lite";

const Login: FC = () => {
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const {store} = useContext(Context);

    return (
        <div className="loginForm">
            <div className="overlord">
                <Container>
                    <Row className="justify-content-md-center mt-5">
                        <Col xs lg="4">
                            <span className="logo">Containera.online</span>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center mt-5">
                        <Col xs lg="4">
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Логин"
                                                  onChange={e => setUsername(e.target.value)}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control type="password" placeholder="Пароль"
                                                  onChange={e => setPassword(e.target.value)}/>
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button className="btn-block" variant="primary" type="button"
                                            onClick={() => store.login(username as string, password as string)}>
                                        Войти
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );

};

export default observer(Login);


