import $api from '../http';
import {AxiosResponse} from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";
import {UpdateResponse} from "../models/response/UpdateResponse";

export default class AuthServices {
    static async login(username: string, password: string) : Promise<AxiosResponse<AuthResponse>> {
      return $api.post('/login', {username, password});
    }
    static async logout() : Promise<void> {
        return $api.post('/logout');
    }

    static async update() : Promise<AxiosResponse<UpdateResponse>> {
        return $api.post('/update');
    }
}


