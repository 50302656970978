import React, {useContext, useEffect, useState} from 'react';
import MovingsList2 from "../components/MovingsList2";
import $api from "../http";
import {useFetching} from "../hooks/useFetching";

import {Context} from "../index";
import Loader from "../components/UI/Loader/Loader";
import ContainersList from "../components/ContainersList";



function Movings2 () {
    const {store} = useContext(Context);
    const [movings, setMovings] = useState(store.movings);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(store.limit)
    const [pageMoving, setPagemoving] = useState([])
    const [totalPage, setTotalpage] = useState(Math.ceil(movings.length / store.limit))
    const getListMoving = (start, end) => {
        let array = [];
        for(start; start < end; start++){
            if(typeof movings[start] !== 'undefined'){
                array.push(movings[start])
            }

        }
        return array;
    }
    const [firstFetchMovings, isPageLoading] = useFetching(async () => {
        if(movings.length > 0){
            let start = (page - 1) * store.limit
            let end = page * store.limit
            setPagemoving(getListMoving(start,end))

        } else {
            const response = await $api.get(`/movings?l=${store.limit}`)
            if (response.status == 200) {
                setPagemoving(response.data.movings);
            }
        }
    })

    const [fetchMovings, isMovingsLoading] = useFetching(async (limit, page) => {
        const response = await $api.get('/movings')
        if(response.status == 200) {
            store.setMovings(response.data.movings)
            setMovings(response.data.movings)
        }
    })

    const changePage = (p) => {
        setPage(p)
    }
    const handlePaginatorCount = (e) => {
        setLimit(e.target.value)
        setPage(1)
    }

    useEffect(() => {
        firstFetchMovings()
        fetchMovings();
        const interval = setInterval(fetchMovings, 180000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        let start = (page - 1) * limit
        let end = page * limit

        setPagemoving(getListMoving(start,end))
        setTotalpage(Math.ceil(movings.length / limit))
    }, [page, movings,limit])

    const handleFilter = (fl) => {
        let _containers = store.movings

        if(fl.number != ''){
            _containers = _containers.filter(container => {
                return  container.number.toLowerCase().includes(fl.number.toLowerCase())
            })
        }


        if(fl.state != ''){
            _containers = _containers.filter(container => {
                if (container.state == fl.state) return container;
            })
        }
        if(fl.content != ''){
            _containers = _containers.filter(container => {
                if (container.content  == fl.content ) return container;
            })
        }
        if(fl.car_driver != ''){
            _containers = _containers.filter(container => {
                return  container.car_driver.toLowerCase().includes(fl.car_driver.toLowerCase())
            })
        }
        if(fl.pcar_driver != ''){
            _containers = _containers.filter(container => {

                return  container.parents.car_driver.toLowerCase().includes(fl.pcar_driver.toLowerCase())
            })
        }
        if(fl.car_number != ''){
            _containers = _containers.filter(container => {
                return  container.car_number.toLowerCase().includes(fl.car_number.toLowerCase())
            })
        }
        if(fl.pcar_number != ''){
            _containers = _containers.filter(container => {
                return  container.parents.car_number.toLowerCase().includes(fl.pcar_number.toLowerCase())
            })
        }
        if(fl.release != ''){
            _containers = _containers.filter(container => {
                return  container.parents.car_number.toLowerCase().includes(fl.pcar_number.toLowerCase())
            })
        }
        setPage(1)
        setMovings(_containers)
    }

    if(isPageLoading) {
        return (
            <Loader />
        );
    }  else {

        return (
            <div className="App">

                <MovingsList2 handleFilter={handleFilter} movingsList={pageMoving} changePage={changePage} page={page} totalPage={totalPage}  isMovingsLoading={isMovingsLoading} handlePaginatorCount={handlePaginatorCount} limit={limit} />
            </div>
        );
    }


}

export default Movings2;
