import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import AuthServices from "../services/AuthServices";
import axios from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";
import {API_URL} from "../http";
import {IContainer} from "../models/IContainer";
export default class Store {
    user = {} as IUser;
    isAuth = false;
    isLoading = false;
    locale = '';
    containers = [{} as IContainer];
    movings = [];
    depot = [];
    prices = [];
    type = [];
    typeOut = [];
    orders = [];
    bills = [];
    currency = '';
    error = '';
    limit = 25;
    version = "1.24.08.27.1";

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean){
        this.isAuth = bool;
    }

    setUser(user: IUser) {
        this.user = user;
    }
    setError(error: string) {
        this.error = error;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }
    setCurrency(currency: string) {
        this.currency = currency;
    }

    setLocale(locale:string) {
        this.locale = locale;
    }
    setDepot(depot:[]) {
        this.depot = depot;
    }
    getDepot() {
        return this.depot;
    }

    setType(type:[]) {
        this.type = type;
    }
    setPrices(prices:[]) {
        this.prices = prices;
    }
    getPrices() {
        return this.prices;
    }
    setTypeOut(typeOut:[]) {
        this.typeOut = typeOut;
    }
    setConteiners(containers:[]) {
        this.containers= containers;
    }
    getContainers() {
       return this.containers
    }
    setMovings(movings:[]) {
        this.movings = movings;
    }

    setOrders(orders:[]) {
        this.orders = orders;
    }

    setBills(bills:[]) {
        this.bills = bills;
    }

    setLimit(limit:number) {
        this.limit = limit;
    }



    async login(username:string, password:string){

            const response = await AuthServices.login(username,password);
            if(response.data.success === true) {
                this.setError('');
                localStorage.setItem('token', response.data.accessToken);
                this.setAuth(true);
                this.setUser(response.data.user);
                this.setType(response.data.type)
                this.setConteiners(response.data.containers)
                this.setMovings(response.data.movings)
                this.setOrders(response.data.orders)
                this.setBills(response.data.bills)
                this.setCurrency(response.data.currency)
                this.setPrices(response.data.prices)
            } else {
                //console.log(response);
                this.setError(response.data.error)
            }
            return response;


    }


    async logout(){
        try {
            const response = await AuthServices.logout();

                localStorage.removeItem('token');
                this.setAuth(false);
                this.setUser({} as IUser);

        }
        catch ({response}) {
            console.log(response);
        }
    }

    async checkAuth(){
        this.setLoading(true);
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials:false})
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
            this.setType(response.data.type)
        }
        catch ({response}) {
            console.log({response});
        } finally {
            this.setLoading(false);
        }
    }

    testContainerNumberToStock(value:string){
       //console.log(value);
        if (value.length == 0) {
            return false;
        }

        if (value.length !== 11) {
            return false;
        }
        for(let i in this.containers){

            if(this.containers[i].number == value){
                //console.log(this.containers[i].number)
                return true;
            }
        }
        return false;
    }

    testContainerNumberToStockUnlock(value:string){
        //console.log(value);
        if (value.length == 0) {
            return false;
        }

        if (value.length !== 11) {
            return false;
        }
        for(let i in this.containers){
            if(this.containers[i].is_blocked == 0) {
                if (this.containers[i].number == value) {
                    //console.log(this.containers[i].number)
                    return true;
                }
            }
        }
        return false;
    }

    testContainer(value:string){
        if (value.length == 0) {
            return false;
        }

        if (value.length !== 11) {
            return false;
        }

        let strValue = value.toUpperCase();

        let isCorrectOwnerCode = /^[A-z]+$/;
        if (!isCorrectOwnerCode.test(strValue.substr(0, 3))) {
            return false;
        }

       let isCorrectContainerCode = /^[A-z]+$/;
        if (!isCorrectContainerCode.test(strValue.substr(3, 1))) {
            return false;
        }

        let isCorrectSerialNumber = /^\d+$/;
        if (!isCorrectSerialNumber.test(strValue.substr(4, 6))) {
            return false;
        }

        let cemap = {
            A:10,B:12,C:13,D:14,E:15,F:16,G:17,H:18,I:19,J:20,K:21,L:23,
            M:24,N:25,O:26,P:27,Q:28,R:29,S:30,T:31,U:32,V:34,W:35,X:36,Y:37,Z:38
        };


        let weights = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];

        let remainders = [0, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

        let controlValue = 0;
        let ce = 0;
        let isNumber = /^\d+$/;
        let index = '';

        for (let i = 0; i < strValue.length - 1; i++) {
            if (isNumber.test(strValue[i])) {
                ce = Number(strValue[i]);
            } else {
                index = strValue[i];
                // @ts-ignore
                ce = cemap[index];
            }
            controlValue += ce * weights[i];
        }

        controlValue = controlValue % 11;

        controlValue = remainders[10 - controlValue];

        let controlNumber = parseInt(strValue[10]);
        if (controlValue !== controlNumber) {
            return false;
        }

        return true;
    }

    getListContainers (start:number, end:number){
        let array = this.getContainers()
        let out:Array<object> = []
        for(start; start < end; start++){
            out.push(array[start])
        }
        return out;
    }

}