import React from 'react';

const Fiostr = (props) => {


    let str = props.fio.trim();
    str = str.split(/\s+/).map((w, i) => i ? w.substring(0, 1).toUpperCase() + '.' : w).join(' ');
        return (
            <>
                {str}
            </>
        );

}

export default Fiostr;