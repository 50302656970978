import React, {useContext, useEffect, useState} from 'react';

import $api from "../http";
import {useFetching} from "../hooks/useFetching";
import Loader from "../components/UI/Loader/Loader";
import {Context} from "../index";
import DepotList from "../components/DepotList";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import DepotListCont from "../components/DepotListCont";
import DepotListHistory from "../components/DepotListHistory";



function Depot () {
    const {store} = useContext(Context);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(store.limit)
    const [items, setItems] = useState([]);
    const [conshow, setConshow] = useState(false);
    const [history, setHystory] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalPage, setTotalpage] = useState(Math.ceil(items.length / store.limit))

    const [fetchDepot, isPageLoading] = useFetching(async (c) => {
        const response = await $api.get(`/depot?cont=${c}`)
        console.log(response.data)
            if (response.status == 200) {
                setItems(response.data.depot);
                store.setDepot(response.data.depot)
                sumaryItems(response.data.depot);
            }
        })



    const changePage = (p) => {

        setPage(p)

    }

    const sumaryItems = (items) => {
        let t = 0;
        items.map((depot, index) => {
            if(typeof depot.rows !== 'undefined'){
                t = t + depot.rows.length
            } else {
                t = items.length
            }
        })
        setTotal(t)
    }

    const changeHistory = (e) => {
        if(history){
            setHystory(false)
            fetchDepot(conshow)
        } else {
            setHystory(true)
            fetchDepot('history')
        }


    }

    const changeContainers = (e) => {
        setConshow(e.target.checked)
        fetchDepot(e.target.checked)

    }

    const handleFilter = (fl) => {
        let _items = store.getDepot()

        if(fl.gruz !== ''){
            _items = _items.filter(item => {
                return  item.gruz.toLowerCase().includes(fl.gruz.toLowerCase())
            })
        }

        if(fl.artikul !== ''){
            _items = _items.filter(item => {
                return  item.artikul.toLowerCase().includes(fl.artikul.toLowerCase())
            })
        }
        if(fl.serials !== ''){
            _items = _items.filter(item => {
                return  item.serials.toLowerCase().includes(fl.serials.toLowerCase())
            })
        }
        if(fl.container_upload !== ''){
            _items = _items.filter(item => {
                return  item.container_upload.toLowerCase().includes(fl.container_upload.toLowerCase())
            })
        }
        if(fl.ucenka !== ''){
            _items = _items.filter(item => {
                return  item.ucenka.toLowerCase().includes(fl.ucenka.toLowerCase())
            })
        }
        if(fl.comment !== ''){
            _items = _items.filter(item => {
                return  item.comment.toLowerCase().includes(fl.comment.toLowerCase())
            })
        }

        setPage(1)
        setItems(_items)
        sumaryItems(_items);
    }

    const handlePaginatorCount = (e) => {
        setLimit(e.target.value)
        setPage(1)
    }

    useEffect(() => {

        fetchDepot();

    }, [])



    if(isPageLoading) {
        return (
            <Loader />
        );
    }  else {

        return (
            <div className="App">
                <Row className="my-3">
                    <h3 style={{textAlign: 'center'}}>
                        Склад
                    </h3>
                    {history ?
                        <h5 style={{textAlign: 'center'}}>История отгрузки </h5> : null}

                </Row>
                <Form>
                    <div className="h4 mb-4 form-check form-switch">
                        {history ? null :
                            <>
                        <input type="checkbox" id="custom-switch" value={1} defaultChecked={false} checked={conshow} className="form-check-input" onClick={(e) => changeContainers(e)}/>
                        <label htmlFor="custom-switch" className="form-check-label">Группировать по контейнерам</label></>
                        }
                    <div style={{float: 'right'}}>
                        <button className="btn btn-secondary"
                                onClick={(e) => changeHistory(e)}>{history ? 'На складе' : 'История'}</button>
                    </div>
                    </div>
                </Form>
                {history? <DepotListHistory totalPage={totalPage} depotList={items} totalItem={total}/> : conshow ?
                    <DepotListCont totalPage={totalPage} depotList={items} totalItem={total}/>
                    :
                    <DepotList totalPage={totalPage} depotList={items} handleFilter={handleFilter} totalItem = {total}  />
                }
            </div>
        );
    }
}

export default Depot;
