import React, {createContext} from "react";
import ReactDOM from "react-dom/client";
import App from './App';
import Store from './store/store';
import {ThemeProvider} from "react-bootstrap";


interface State {
    store: Store;
}

const store = new Store();

export const Context = createContext<State>({
    store,
})
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <Context.Provider value={{
        store

    }}><ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
    >
        <App />
    </ThemeProvider>
    </Context.Provider>
    );






