import React from 'react';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import classes from "./MyButton.module.css";

const MyButton = ({children,...props}) => {
    return (
        <Button variant={props.variant} className={classes.myBtn}>
            <Link to={props.to} className={classes.myLink} >
                {children}
            </Link>
        </Button>
    );
};

export default MyButton;
