import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Datelocale from "./UI/Datelocale";
import {useIntl} from "react-intl";
import Icon from "@reacticons/bootstrap-icons";
import Doclist from "./UI/Doclist";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";


const RepairItem = (props) => {
    const intl = useIntl();
    let status = {1:{title:intl.messages.new, bage:'bg bg-danger', alert:'alert mb-3 alert-danger'}, 2:{title:intl.messages.waiting, bage:'bg bg-warning', alert:'alert mb-3 alert-warning'},3:{title:intl.messages.in_work, bage:'bg bg-info', alert:'alert mb-3 alert-info'},4:{title:intl.messages.ready, bage:'bg bg-success', alert:'alert mb-3 alert-success'},5:{title:intl.messages.canceled, bage:'bg bg-secondary', alert:'alert mb-3 alert-secondary'}}





    return (
        <>
            <Row className={status[props.repair.status].alert}>
                <Col lg={4} sm={6} xs={12}>
                    <div>
                        <span className="text-muted">{intl.messages.container}:</span>
                        {props.repair.c_number} <span className={status[props.repair.status].bage}>{status[props.repair.status].title}</span>
                    </div>
                    <div>
                        <span className="text-muted">{intl.messages.created}:</span>
                        <Datelocale date={props.repair.created} />
                    </div>
                    <div>
                        <span className="text-muted">{intl.messages.createdBy}:</span>
                        {props.repair.createUserName}
                    </div>
                    {props.repair.agreed_user_id > 0 ? <>
                        <div>
                            <span className="text-muted">{intl.messages.agreed}:</span>
                            {props.repair.agreeUserName}
                        </div>

                    </> : null }

                </Col>
                <Col lg={4} sm={6} xs={12}>

                    <div>
                        {props.repair.crane ? <>
                        <span className="text-muted">{intl.messages.crane_operations}:</span>
                        {props.repair.crane}  {intl.messages.pc} </>
                            : null }

                    </div>
                    <div>
                        {props.repair.type == 'insulation' ? <>
                        <span className="text-muted">
                                    {props.repair.nom_text}
                            </span> {props.repair.rrsum} ₽ <br/>
                                <span
                                    className="text-muted">{intl.messages.total} : </span> {props.repair.rrsum} ₽
                                <br/>
                            </>
                            : <>
                                {props.repair.rows.map((rrepair, index) => <>
                                <span className="text-muted">
                                    {localStorage.getItem('locale') == "ru-RU" ? rrepair.name : rrepair.eng }
                                     : </span> {rrepair.price}{props.repair.currency} x {rrepair.amount} = {rrepair.sum}{props.repair.currency} <br />
                                </>
                            )
                            }
                                <span className="text-muted">{intl.messages.total} : </span> {props.repair.repair} {props.repair.currency} <br />
                            </>

                        }

                    </div>


                </Col>
                <Col lg={4} sm={6} xs={12}>
                    <div>
                        <Doclist photo={props.repair.photo} />
                        <Badge bg="info" className="pointer" data-id={props.repair.id} data-number={props.repair.c_number} onClick={props.downloadPhoto} >{intl.messages.download}</Badge>
                    </div>
                    {(props.repair.status === 4) ? <>
                        <div>
                            <span className="text-muted">{intl.messages.ready_repair}:</span>
                            <Datelocale date={props.repair.completed_date} />
                        </div>

                    </>: null
                    }

                </Col>
                <Col lg={4} sm={6} xs={12}>
                    {(props.repair.status === 2) ? <>
                        <button className="btn btn-success" data-id={props.repair.id} data-number={props.repair.c_number} onClick={props.acceptRepair}  type="button">{intl.messages.accept}</button>
                        <button className="btn btn-danger mx-2" data-id={props.repair.id} data-number={props.repair.c_number} onClick={props.deniedRepair}  type="button">{intl.messages.refuse}</button>
                    </> : null}
                </Col>

            </Row>
        </>
    );
};

export default RepairItem;
