import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';

import {Context} from "../index";
import ReleaseFormOutContainer from "../components/ReleaseFormOutContainer";
const CreateReleaseByContainer = () => {
    const {id} = useParams();
    const {store} = useContext(Context);
    let container = store.containers.find(e => e.id == id);
    const release = {id:0, date_from:'', date_to:'', release:'',type_release:'OUT',description:'',user:store.user.name, add_email:'', status:1, containers:[{number:container.number, size:container.size,type:container.type,tonnage:container.tonnage,count:1}], cars:[{number:'', fio:'',phone:'',count:1}]};
        return (
            <ReleaseFormOutContainer release={release} />
        );

};

export default CreateReleaseByContainer;
