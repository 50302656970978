import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import $api from "../http";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {useIntl} from "react-intl";
import Loader from "./UI/Loader/Loader";
import Button from "react-bootstrap/Button";

const InsulationForm = () => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const [save, setSave] = useState(false)
    const insulation_type = [{value:1,title:intl.messages.one_layer},{value:2,title:intl.messages.two_layer},{value:3,title:intl.messages.three_layer}];
    const type_container = [{value:'b', title:"40`"}, {value:'s',title:"20`"}];
    const [insulation, setInsulation] = useState({id:0, type:"insulation",container_number:'', type_container:'', driver:'',car:'',name:"",roof:0,plas:0});
    let navigate = useNavigate();
    const [total, setTotal] = useState("0 ₽");

    const addNewPost = async (e)  => {
        e.preventDefault()
        setSave(true)
        const response = await $api.post('/services/insulation/save', insulation);
        if(response.status == '200'){
            navigate('/services');
        } else {
            setSave(false)
        }
    }

    const handleChange = (e,name) => {
        let _insulation = {...insulation};
        _insulation[name] = e.target.value;
        setInsulation(_insulation);
        totalSum(_insulation);
    }
    const handleChangeCheckbox = (e,name) => {
        let value = 0;
        if(insulation[name] === 0){
            value = 1;
            e.target.checked = true;
        } else {
            value = 0
            e.target.checked = false;
        }

        let _insulation = {...insulation};
        _insulation[name] = value;
        if(name == 'plas' && value == 1){
            document.getElementById('roofcheckbox').checked = true
            _insulation['roof'] = 1;
        }
        if(name == 'roof' && value == 0){
            document.getElementById('plascheckbox').checked = false
            _insulation['plas'] = 0;
        }
        setInsulation(_insulation);

        totalSum(_insulation);
    }
    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
        } else {

            addNewPost(e);
        }
    };

    const totalSum = (temp) => {
        let _total = 0;
        if(temp.type_container && temp.name) {
                if(temp.name == 1){
                    if (temp.roof == 1 && temp.plas == 1) {
                        _total = store.prices.value[3][temp.type_container]
                    } else if(temp.roof == 1){
                        _total = store.prices.value[2][temp.type_container]
                    } else {
                        _total = store.prices.value[1][temp.type_container]
                    }
                } else if (temp.name == 2){
                    if (temp.roof == 1 && temp.plas == 1) {
                        _total = store.prices.value[6][temp.type_container]
                    } else if(temp.roof == 1){
                        _total = store.prices.value[5][temp.type_container]
                    } else {
                        _total = store.prices.value[4][temp.type_container]
                    }
                } else if (temp.name == 3){
                    if (temp.roof == 1 && temp.plas == 1) {
                        _total = store.prices.value[9][temp.type_container]
                    } else if(temp.roof == 1){
                        _total = store.prices.value[8][temp.type_container]
                    } else {
                        _total = store.prices.value[7][temp.type_container]
                    }
                }

            setTotal(_total + " ₽")
        } else {
            setTotal("0 ₽")
        }

    }

    if(save){
        return (
            <Loader />
        )
    }

    return (

        <Form noValidate onSubmit={handleSubmit} className="was-validated">
            <h3 className="my-5">{intl.messages.application_insulation}:</h3>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label as={Col} sm="3">
                    {intl.messages.number_container}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text"  value={insulation.container_number} onChange={(e)=>handleChange(e,'container_number')} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.size}:
                </Form.Label>
                <Col sm="9">
                    <Form.Select value={insulation.type_container} onChange={e => handleChange(e, 'type_container')} required>
                        <option></option>
                        {type_container.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}

                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateTo">
                <Form.Label column sm="3">
                    {intl.messages.driver}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={insulation.driver} onChange={(e)=>handleChange(e,'driver')}  placeholder="" required/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.car}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={insulation.car} onChange={(e)=>handleChange(e,'car')} required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.insulation}:
                </Form.Label>
                <Col sm="9">
                    <Form.Select value={insulation.name} onChange={e => handleChange(e, 'name')} required>
                        <option></option>
                        {insulation_type.map((s, i) =>
                            <option key={i} value={s.value}>{s.title}</option>
                        )}

                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.roof}:
                </Form.Label>
                <Col sm="9">
                    {insulation.roof ?
                        <Form.Check id="roofcheckbox" type="checkbox" value="1" checked="true" onChange={(e)=>handleChangeCheckbox(e,'roof')}   placeholder=""  />:
                        <Form.Check id="roofcheckbox" type="checkbox" value="1" onChange={(e)=>handleChangeCheckbox(e,'roof')}   placeholder=""  />
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.plas}:
                </Form.Label>
                <Col sm="9">
                    {insulation.plas ?
                        <Form.Check id="plascheckbox" type="checkbox" value="1" checked="true" onChange={(e)=>handleChangeCheckbox(e,'plas')}   placeholder=""  />:
                        <Form.Check id="plascheckbox" type="checkbox" value="1" onChange={(e)=>handleChangeCheckbox(e,'plas')}   placeholder=""  />
                    }
                </Col>
            </Form.Group>
            <hr />
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.total}:
                </Form.Label>
                <Col sm="9">
                    <h4>{total}</h4>
                </Col>
            </Form.Group>
            <Row className="mb-3" >
                <Col sm={{span: 3}}>
                    <Button variant="primary" type="submit">{intl.messages.save}</Button>
                </Col>

            </Row>
        </Form>

    );
};

export default InsulationForm;
