import React from 'react';
import Fancybox from "./Fancybox";



const DepotItemCont = (props) => {

    const visibleTr = (e) => {
        let _row = e.target.dataset.name;
        console.log(_row)
        const addClassElements =  document.querySelectorAll('.tr-cont-'+_row)

        if(addClassElements !== null) {
            for (let elem of addClassElements) {
                if(elem.classList.contains('activeTr')){
                    elem.classList.remove('activeTr')
                } else {
                    elem.classList.add('activeTr')
                }
            }
        }
    }



   return (
        <>
            <tr className="bg-success" data-name={props.num} onClick={(e) => visibleTr(e)}>
                <td className="text-light" data-name={props.num}>{props.num + 1}.</td>


                <td colSpan="3" className="text-light" data-name={props.num}><strong
                    data-name={props.num}>{props.depot.number}</strong></td>
                <td colSpan="4" className="text-light" data-name={props.num}><strong
                    data-name={props.num}>Брак - {props.depot.rows.length}</strong></td>
            </tr>
            {props.depot.rows.map((container, i) =>
                <tr key={i} className={"tr-hide tr-cont-" + props.num}>
                <td></td>
                <td>{container.gruz}</td>
                <td>{container.artikul}</td>
                <td>{container.serials}</td>
                <td>{container.comment}</td>
                <td>{container.ucenka}</td>
                <td><Fancybox>
                    {container.photos.map((photo, ind) =>

                        <a key={ind} className="mx-2" data-fancybox="gallery"
                           data-download-src={'https://containera.online/app' + photo.patch}
                           href={'https://containera.online/app' + photo.patch}>Фото{ind + 1}</a>
                    )}</Fancybox>
                </td>
            </tr>
        )}

        </>
   );
};

export default DepotItemCont;
