import React from 'react';
import {Link} from 'react-router-dom';
import Datelocale from "./UI/Datelocale";
import Doclist from "./UI/Doclist";
import Fiostr from "./UI/Fiostr";

const MovingItem2 = (props) => {
    const state = {A:"Исправный",U:"Требует ремонта",UA:"Отремонтирован"}
    const content = {E:"Порожний",F:"Груженный"}
 /*   const clickRow = (e) => {

        let link = "/release/" + e.target.closest("tr").dataset.link
        navigate(link)
    }
*/
    const setDocs = () => {
        let doc = [];
        if(props.moving.patch_act){
            doc.push(props.moving.patch_act)
        }
        if(props.moving.patch_ttn){
            doc.push(props.moving.patch_ttn)
        }
        if(props.moving.patch_front){
            doc.push(props.moving.patch_front)
        }
        if(props.moving.patch_left){
            doc.push(props.moving.patch_left)
        }
        if(props.moving.patch_right){
            doc.push(props.moving.patch_right)
        }
        if(props.moving.patch_rear){
            doc.push(props.moving.patch_rear)
        }

        if(props.moving.photo != null && props.moving.photo.length > 0 ){
            for(let r in props.moving.photo){
                doc.push(props.moving.photo[r])
            }
        }
        return doc;

    }
    const setDocs2 = () => {
        let doc = [];
        if(props.moving.parents.patch_act){
            doc.push(props.moving.parents.patch_act)
        }
        if(props.moving.parents.patch_ttn){
            doc.push(props.moving.parents.patch_ttn)
        }
        if(props.moving.patch_front){
            doc.push(props.moving.patch_front)
        }
        if(props.moving.patch_left){
            doc.push(props.moving.patch_left)
        }
        if(props.moving.patch_right){
            doc.push(props.moving.patch_right)
        }
        if(props.moving.patch_rear){
            doc.push(props.moving.patch_rear)
        }

        if(props.moving.parents.photo != null && props.moving.parents.photo.length > 0 ){
            for(let r in props.moving.parents.photo){
                doc.push(props.moving.parents.photo[r])
            }
        }
        return doc;

    }

    return (
        <><tr >


            <td>{props.moving.number}</td>
            <td>{state[props.moving.state]}</td>
            <td>{content[props.moving.content]}</td>
            <td><Link to={`/release/view/${props.moving.release_id}`}>{props.moving.release_id}</Link></td>
            <td>
                <Datelocale date={props.moving.created} />
            </td>
            <td>{props.moving.car_number}</td>

            <td>{props.moving.car_driver ? <Fiostr fio={props.moving.car_driver} />: null}</td>

            <td><Doclist photo={setDocs()} /></td>
            <td>{props.moving.days}</td>
            <td><Link to={`/release/view/${props.moving.parents.release_id}`}>{props.moving.parents.release_id}</Link></td>
            <td>{props.moving.parents.release}</td>
            <td>{
                !props.moving.parents.created ?
                    null :
                <Datelocale date={props.moving.parents.created} />
            }</td>
            <td>{props.moving.parents.car_number}</td>
            <td>{props.moving.parents.car_driver ? <Fiostr fio={props.moving.parents.car_driver} /> : null}</td>

            <td><Doclist photo={setDocs2()} /></td>
            <td></td>



        </tr>
        </>
    );
};

export default MovingItem2;
