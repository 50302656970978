import React, {useContext, useEffect, useState} from 'react';
import MovingsList from "../components/MovingsList";
import $api from "../http";
import {useFetching} from "../hooks/useFetching";

import {Context} from "../index";



function Movings () {
    const {store} = useContext(Context);
    const [movings, setMovings] = useState(store.movings);
    const [fetchMovings, isPostsLoading, postError] = useFetching(async (limit, page) => {
        const response = await $api.get('/movings')
        if(response.status == 200) {
            setMovings(response.data.movings);
            store.setMovings(response.data.movings);
        } else {
            store.logout();
        }
    })

    useEffect(() => {
        fetchMovings();
        const interval = setInterval(fetchMovings, 180000);
        return () => clearInterval(interval);
    }, [])




    return (
        <div className="App">
            <MovingsList movingsList={movings} />
        </div>
    );
}

export default Movings;
