import React from 'react';

const DateTimelocale = (props) => {
    let date = new Date(props.date);

    return (
            <>
                {date.toLocaleString()}
            </>
        );

}

export default DateTimelocale;